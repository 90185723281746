import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {StaticImage} from "gatsby-plugin-image"
import {SectionImageBkg} from "../Section"
import {LineArrowRightIcon} from "../Icons"
import { useStaticQuery, graphql } from "gatsby"

const CardPrimary = styled.div`
    background-color:#1383C1;
    border-radius:20px;
    overflow:hidden;
    position:relative;
    z-index: 1;
    /* @media(min-width:1600px){
        transform: scale(1.1);
    } */

`
const CardPrimaryHeading = styled.div`
    position:relative;
    padding:30px 30px 0 30px;
    @media(min-width:1600px){
        padding:60px 60px 0 60px;
    }
`
const CardPrimaryTitle = styled.h2`
    text-transform:uppercase;
    color:#fff;
    
    font-size: 32px;
    line-height: 32px;
    @media (min-width: 992px) {
        font-size: 40px;
        line-height: 40px;
    }
    @media (min-width: 1200px) {
        font-size: 50px;
        line-height: 50px;
    }
    @media (min-width: 1600px) {
        font-size: 60px;
        line-height: 60px;
    }
    @media (min-width: 1600px) {
        font-size: 65px;
        line-height: 65px;
    }
`
const CardPrimaryBody = styled.div`
    position:relative;
    padding:0 30px 30px;
    @media(min-width:1600px){
        padding:0 60px 60px;
    }
`

const ListStructures = styled.div`
    font-weight:600;
    font-size: 18px;
    line-height: 28px;
    @media (min-width: 1440px) {
        font-size: 20px;
        line-height: 30px;
    }
    @media (min-width: 1600px) {
        font-weight:700;
        font-size: 26px;
        line-height: 36px;
    }

    
`
const ListStructureItem = styled(Link)`
    display:flex;
    align-items:center;
    justify-content:space-between;
    color:#fff;    
    + a{
        margin-top:12px;
        @media (min-width: 1600px) {
            margin-top:20px;
        }
    }    
    .text{
        position:relative;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            height: 2px;
            transition: -webkit-transform .4s cubic-bezier(.77,0,.175,1);        
            transition: transform .4s cubic-bezier(.77,0,.175,1);
            transition: transform .4s cubic-bezier(.77,0,.175,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);        
            transform: translateZ(0) scaleX(1);
            background: #F8A24C;
            transform-origin: right center;    
            transform: translateZ(0) scaleX(0);
        }
        + .icon{
            margin-left:10px;
        }
    }
    .icon{
        > svg{
            fill:#FFD6B4;
            transition: transform 0.3s ease-out;
        }
    }
    &:hover, &:focus{
        color: #F8A24C;
        .text{
            &:after {
                transform-origin: left center;        
                transform: translateZ(0) scaleX(1);
            }
        }
        .icon{
            > svg{
                fill:#F8A24C;
                transform: translateX(4px);
            }
        }
    }
    
`

const StructuresTypes = () => {
    const data = useStaticQuery(
        graphql`
          query {
            allContentfulProductCategory(sort: {order: ASC, fields: menuOrder}) {
              edges {
                node {
                  name
                  url
                }
              }
            }
          }
        `
    );
  return (
    <CardPrimary className="card-primary">
        <SectionImageBkg opacity="0.20"><StaticImage src="../../images/blue-pattern.jpg" alt="blue-pattern" /></SectionImageBkg>
        <CardPrimaryHeading className="card-heading">
            <CardPrimaryTitle>ALL TYPES OF STRUCTURES:</CardPrimaryTitle>
        </CardPrimaryHeading>
        <CardPrimaryBody className="card-body">
            <ListStructures>
                {
                    data.allContentfulProductCategory.edges.map((item, i) => (
                        <ListStructureItem to={item.node.url}><span className="text">{item.node.name}</span> <span className="icon"><LineArrowRightIcon /></span></ListStructureItem>
                    ))
                }
            </ListStructures>
        </CardPrimaryBody>                            
    </CardPrimary>
  )
}

export default StructuresTypes
