import React, { useState, useEffect, useContext } from 'react'
import { navigate } from "gatsby"
import styled from 'styled-components'
import { SearchIcon, StarIcon, CallIcon }  from "../../components/Icons"
import { Context } from '../context';

const SearchToolbar = styled.div`
	background:linear-gradient(180deg, #000000 0%, #222222 100%);
    color: #fff;
    box-shadow: 0 0 50px rgb(0 0 0 / 50%);
    position: relative;
    z-index: 2;
    bottom:0;
    margin:0 auto;
    max-width:1130px;
    width:100%;
    @media(min-width:992px){
        border-radius: 15px;
    }
    @media(min-width:1200px){
        border-radius: 20px;
    }

`
const SearchGrid = styled.div`
    position:relative;
    display:flex;
    flex-wrap:wrap;   
    
    .search-item{
        &:first-child, &:last-child{
            @media(max-width:991.98px){
                display:none;
            }
        }
    } 
`
const SearchItem = styled.div`
    position:relative;
    width:100%;
    border-right:1px solid rgba(255,255,255,0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding: 15px;
    @media (min-width:992px){
        padding: 15px 20px;
    }
    @media (min-width:1200px){
        padding: 15px 30px;
    }
    @media (min-width:1600px){
        padding: 25px 40px;
    }
    .btn-search{
        display:flex;
        align-items:center;
        text-transform:uppercase;
        color:#fff;
        .icon{
            display: flex;
            align-items: center;
            height:48px;
            + .text{
                margin-left:15px;
            }
            svg{
                fill:#fff;
            }
        }
        .text{
            span, small{
                display:block;
            }
            span{
                font-weight:600;
                font-size:16px;
                line-height:20px;
                white-space: nowrap;
                @media(min-width:992px){
                    font-size:18px;
                    line-height:22px;
                }
                @media(min-width:1200px){
                    font-size:20px;
                    line-height:24px;
                }                
            }
            small{
                font-weight:700;
                color:#1383C1;
                font-size:14px;
                line-height:24px;
                transition: all 0.5s ease;
                text-align:left;
                @media(min-width:1200px){
                    font-size:16px;
                    line-height:26px;
                }
            }
        }
        &:hover, &:focus{
            small{
                color:#fff;
            }
        }
    }
   

`
const SearchInput = styled.div`
    position:relative;
    width:100%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    display: flex;
    align-items: center;
    padding:0 40px;

    padding: 0 25px;
    @media (min-width:1200px){
        padding: 0 30px;
    }
    @media (min-width:1600px){
        padding: 0 40px;
    }
    .search-form{
        width:100%;
    }
    .input-icon{
        .form-control{
            color:#fff;
            font-size: 16px;
            line-height: 20px;
            @media (min-width: 1200px) {
                font-size: 18px;
                line-height: 22px;
            }
            @media (min-width: 1600px) {
                font-size: 20px;
                line-height: 24px;
            }
            &::placeholder {
                color: rgba(255, 255, 255, 0.40);
            }
            &:focus,
            &:active {
                &::placeholder {
                    color: rgba(255, 255, 255, 0.40);
                }
            }
        }
        .icon{
            svg{
                fill:#fff;
            }
        }
    }

    button {
        display: none;
    }

`
const SearchBar = () => {
    const [searchKey, setKey] = useState(null)
    const [reviewPopupContext, setReviewPopupContext] = useContext(Context)

    const handleSubmit = (e) => {
       e.preventDefault();
       navigate("/search-result", { state: { searchKey: searchKey } });
    }    
    
    useEffect(() => {
      if (reviewPopupContext) {
          document.querySelector("body").classList.add("modal-open")
      } else {
          document.querySelector("body").classList.remove("modal-open")
      }
    }, [reviewPopupContext, setReviewPopupContext])
    
  return (
    <>
        <SearchToolbar className='search-bar'>
            <SearchGrid>
                <SearchItem className='search-item'>
                    <a href='tel:3869610006' className='btn-search' aria-label="Phone">
                        <span className='icon'><CallIcon /></span>
                        <span className='text'>
                            <small>Call US At</small>
                            <span>386-961-0006</span>
                        </span>
                    </a>
                </SearchItem>            
                <SearchItem>
                    <button type='button' onClick={()=>setReviewPopupContext(true)} className='btn-search'>

                        <span className='icon'><StarIcon /></span>
                        <span className='text'>
                            <span>How did we do? </span>
                            <small>Rate & Review Us.</small>
                        </span>
                    </button>
                </SearchItem>
                <SearchInput className='search-item'>
                    <form className='search-form' onSubmit={handleSubmit}>
                        <div className="input-icon right">
                            <input  className="form-control" type="text" placeholder="Keyword Search…" onChange={(e) => setKey(e.target.value)} required />
                            <span className="icon"><SearchIcon /></span>
                            <button type="submit">Update post</button>
                        </div>
                    </form>
                </SearchInput>
            </SearchGrid>
        </SearchToolbar>
    </>
  )
}

export default SearchBar
