import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const PrimaryLink = styled(BaseButton)`		
	padding:0 !important;
	color:#1383C1;	
	& .icon{
		> svg{
			fill:#1383C1;
		}
	}
	&.btn-underline{
		.text{
			&:after{
				background-color:#E08932;
			}
		}
	}
	&:hover, &:focus{
		color:#E08932;
		& .icon{
			> svg{
				fill:#E08932;
			}
		}
	}
`

const PrimaryLinkButton = (props) => {
	const { icon, iconAfter, text, size, decoration } = props;
	return(
		<PrimaryLink className={`btn ${size ? `btn-${size}` : ''} ${decoration ? `btn-${decoration}` : ''} `}>
			{ iconAfter ? (
				<>
					{ text && <span className='text'>{text}</span>}
					{ iconAfter && <span className='icon'>{iconAfter}</span>}
				</>
				):(
					<>
						{ icon && <span className='icon'>{icon}</span>}
						{ text && <span className='text'>{text}</span>}
					</>
				)
			}
		</PrimaryLink>
	)
}

export default PrimaryLinkButton