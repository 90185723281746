import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import styled from "styled-components"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image"
import {SectionHero, Section, SectionDescription, SectionPageTitle, SectionImageBkg, ExploreMore} from "../components/Section"
import {LineArrowRightIcon} from "../components/Icons"
import SearchBar from "../components/SearchBar"
import StructuresTypes from "../components/StructuresTypes"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton"
import WhiteLinkButton from "../components/Button/WhiteLinkButton"
import DealerForm from "../components/DealerForm"

const SectionHeros = styled(SectionHero)`
	position:relative;
    height: 100%;    
    background-color:#E1ECF4;
`

const HeroBanner = styled.div`	
    position: absolute;
    width:100%;
    top:0;
    left:0;
    height: calc(100% - 80px);
    border-bottom:1px solid #ccc;
    @media (min-width:992px){
        position:relative;
        height:100%;
    }
    .gatsby-image-wrapper {
        width:100%;
        height:100%;
        opacity:0.1;
    }  
`

const HeroCaption = styled.div`	
    position:relative;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    z-index: 1;
    @media (min-width:992px){
        position:absolute;
    }
    .container{
        height:100%;
    }
`
const HeroSearch = styled.div`   
    
    @media (min-width:1200px){
        position: relative;
        bottom: 40px;
    }
    @media (min-width:1600px){
        bottom:50px;
    }
    .search-bar{
        @media (min-width:992px){
            margin-left:0;
            z-index: 1;
            width:100%;
        }
        @media (min-width:1200px){
            max-width: 920px;
        }
        @media (min-width:1440px){
            max-width: 1020px;
        }
        @media (min-width:1600px){
            max-width: 1040px;
        }
        .container{
            @media (max-width:991.98px){
                padding:0;
            }
        }
    }
    
`
const HeroDesignCta = styled.div`
    text-align:center;
    margin:0 0 40px;
    @media (min-width:992px){
        text-align:left;
    }
    > a{
        display:inline-block;
    }
`


const DesignCta = styled.div`   
  position:relative;
  z-index:1;
  background-color:#1383C1;
  display:inline-flex;
  flex-wrap:nowrap;
  align-items:center;
  border-radius:20px;
  box-shadow:0 0 50px rgba(0,0,0,0.5);
  padding:10px 30px;
  @media (min-width: 768px) {
    padding:10px 20px;
  }
  @media (min-width: 992px) {
    padding:10px 30px;
  }

  .btn{
    font-family:'Teko', sans-serif;    
    font-size: 30px;
    line-height: 30px;
    text-align:left;
    @media (min-width: 768px) {
        font-size: 24px;
        line-height: 24px;
    }
    @media (min-width: 992px) {
        font-size: 30px;
        line-height: 30px;
    }
  }
`
const DesignIcon  = styled.span`
    max-width:70px;
  @media (min-width: 1600px) {
    max-width:inherit;
  }  
  + .btn{
    margin-left:20px;
  }
`

const HeroCaptionGrid = styled.div`
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    margin:0 -15px;   
    @media (min-width: 1200px) {
        margin:0 -30px;
    }   
    
`
const HeroCaptionLeft = styled.div`
    position: relative;
    width:100%;
    display:flex;
    flex-wrap:wrap;
    padding: 40px 15px;    
    @media (min-width: 768px) {   
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media (min-width: 992px) {
        padding: 40px 30px;
    }   
    @media (min-width: 1200px) {
        padding: 40px 30px 230px 30px;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
        
    }   
    @media (min-width: 1440px) {
        align-items:center;
    }   
`
const HeroCaptionRight = styled.div`
    position: relative;
    width:100%;
    
    padding: 0 15px 60px 15px;
    @media (min-width: 768px) {   
        flex: 0 0 50%;
        max-width: 50%;
        padding: 40px 15px;
    }
    @media (min-width: 992px) {
        padding: 40px 30px;
    }
    @media (min-width: 1200px) {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .card-primary{
        transform: scale(1);
        .card-heading{
            max-width: 500px;
            width: 100%;
            margin: 0 auto;
        }
        .card-body{
            max-width: 500px;
            width: 100%;
            margin: 0 auto;
            padding:0 30px 40px;
            @media(min-width:1200px){
                padding:0 35px 200px;
            }
            @media(min-width:1600px){
                padding:0 60px 220px;
            }
        }
    }
`


const SectionBanner = styled(SectionHero)`
  position:relative;
  height: 100%;
`
const FlexHeroCaption = styled.div`	
  position:relative;
  z-index:1;
  width: 100%;
  padding:80px 0 40px;
  @media (min-width:1200px){
    padding:60px 0;
  }
  @media (min-width:1600px){
    padding:90px 0;
  }
`

const SectionImageBkgs = styled(SectionImageBkg)`
    right:0;
    left:auto;
    opacity:0.5;
    @media (min-width:768px){
        width: 80%;
        opacity:0.18;
    }
    &:after {
        display: block;
        width: 100%;
        background:rgba(255,255,255,0.8);
        @media (min-width:768px){
            background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
            width: 50%;
        }
    }
`

const LeadCheck = styled.div`
    display:flex;
    flex-wrap:wrap;
    align-items:center;
`
const Flexbox = styled.div`
	display:flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content:space-between;
    margin:0 -15px;
    @media (min-width: 1200px) {
        margin:0 -30px;
    }
    @media (min-width: 1600px) {
        margin:0 -50px;
    }
`
const FlexboxLeft = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`
const FlexboxRight = styled.div`
  position:relative;
  text-align:right;
  width:100%;
  padding:0 15px;
  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .gatsby-image-wrapper{
    display:inline-block;
  }
`

const CardForm = styled.div`
    border-radius: 20px;
    max-width:1090px;
    width:100%;
    margin:0 auto;
    position: relative;
    box-shadow:0 0 99px rgba(0,0,0,0.2);
    overflow:hidden;
    &:after{
        content: '';
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:50%;
        background: transparent linear-gradient(182deg, #F8FDFE 0%, #F8FDFE00 100%) 0% 0% no-repeat padding-box;
    }
   
`
const CardFormBody = styled.div`
    position:relative;
    z-index: 1;
    padding:30px 30px 100px 30px;
    @media (min-width:576px){
        padding:40px 60px 180px 60px;
    }
    @media (min-width:768px){
        padding:40px 100px 180px 100px;
    }
    @media (min-width:1200px){
        padding:80px 205px 280px 205px;
    }
    .form{
        .form-action{
            justify-content:center;
        }
        .form-group textarea{
            min-height:140px;
        }
    }
`
const CardFormTitle = styled.h2`
    text-transform:uppercase;
    text-align:center;
    margin-bottom:15px;
`
const CardFormDescription = styled(SectionDescription)`
    margin-bottom:15px;
`

const BecomeDealerPage = ({ data, location }) => {
    const Scrollify = () =>
    document
      .querySelector("#join")
      .scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
   return(
        <Layout location={location}>
            <Seo
              title={data.allContentfulPageData.edges[0].node.metaTitle}
              description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
            />
            <SectionHeros className="section-hero">
                <HeroBanner><StaticImage src="../images/inside-small-carport.jpg" alt="inside-small-carport" /></HeroBanner>
                <HeroCaption>
                    <div className="container">
                        <HeroCaptionGrid>
                            <HeroCaptionLeft>
                                <div>
                                    <SectionPageTitle className="h2" mb="0">Join Our Team and Become a Part of Our Success Story</SectionPageTitle>
                                    <SectionDescription fontStyle="italic" maxWidth="810px" mb="15px" ml="0">
                                        <p>Join us and be part of a team that manufactures high-quality metal buildings. Offer a wide range of steel building options to the customers and guide them to make the right choice. We're committed to helping you at every step of the way. Let's achieve greatness together!</p>
                                    </SectionDescription>
                                    <ExploreMore justifyContent="flex-start"><button type="button" onClick={Scrollify} aria-label="Join Us"><PrimaryLinkButton  iconAfter={<LineArrowRightIcon />} text="Join Us" decoration="underline" /></button></ExploreMore>
                                </div>
                            </HeroCaptionLeft>
                            <HeroCaptionRight>
                                <StructuresTypes/>
                            </HeroCaptionRight>
                        </HeroCaptionGrid>
                    </div>
                </HeroCaption>
                <HeroSearch>
                    <div className="container">                
                        <HeroDesignCta>
                            <a href="https://tubularbuildings.sensei3d.com/" target="_blank" rel="noreferrer">
                                <DesignCta>
                                    <DesignIcon><StaticImage src="../images/launch-designer.png" placeholder="blurred" alt="Launch 3D Designer"/></DesignIcon>
                                    <WhiteLinkButton decoration="underline"  iconAfter={<LineArrowRightIcon />} text="Launch 3D Designer" />
                                </DesignCta>
                            </a>
                        </HeroDesignCta>
                        <SearchBar />
                    </div>
                </HeroSearch>
            </SectionHeros>

            <SectionBanner className="section-hero">
                <SectionImageBkgs><StaticImage src="../images/coverage-area-hero.jpg" alt="coverage-area-hero" /></SectionImageBkgs>
                <FlexHeroCaption>
                    <div className="container">
                        <Flexbox>
                            <FlexboxLeft>
                                <SectionPageTitle className="h2" ml="0">LOCATIONS NEARBY</SectionPageTitle>
                                <SectionDescription maxWidth="790px" ml="0" fontStyle="italic">
                                    <p>We service all of Florida with the exception of Monroe County (the Florida Keys) and we service Southern Georgia.</p>
                                </SectionDescription>
                                <LeadCheck>
                                    <StaticImage src="../images/check.png" alt="check" />
                                    <strong>Lead time is 4-6 weeks</strong>
                                </LeadCheck>
                            </FlexboxLeft>
                            <FlexboxRight>
                                <StaticImage src="../images/map-location.png" alt="map-location" />
                            </FlexboxRight>
                        </Flexbox>
                    </div>
                </FlexHeroCaption>
            </SectionBanner>
            
            <Section pt="100px" pb="50px" xpt="60px" mpt="40px" bgColor="#F8F8F8" className="section-help" id="join">
                <div className="container">
                    <CardForm>
                        <SectionImageBkg height="50%" top="50%">
                            <StaticImage src="../images/contact-bkg.jpg" alt="contact background" />
                        </SectionImageBkg>
                        <CardFormBody>
                            <CardFormTitle className="h4 mb-0">Join Us</CardFormTitle>
                            <CardFormDescription textAlign="center" color="#707070"><p>Start your journey to success by becoming our dealer today</p></CardFormDescription>
                            <DealerForm location={location} />
                        </CardFormBody>
                    </CardForm>
                </div>
            </Section>
        </Layout>
   )
}
export default BecomeDealerPage

export const pageQuery = graphql`
  query BecomeDealerPageQuery{
    allContentfulPageData(filter: {page: {eq: "Become A Dealer"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
  }
`